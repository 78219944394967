.profile {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    text-decoration: none;
    color: var(--secundary-color);
}

.profile__image {
    margin-right: 1rem;
    margin-bottom: .5rem;

    img {
        max-width: 7.5rem;
    }
}

.profile__title {
    font-size: 1.5rem;
    line-height: 1.9rem;
    color: #616161;
}

@media all and (min-width: 1024px){
    .card-navigation {
        display: none;
    }

    .card-content {
        display: flex;
    }
}

@media all and (min-width: 1024px){
    .card-navigation {
        display: flex;
    }
    
    .card-content {
        display: none;
    }
}