address {
    color: #5c5c5c;
    font-size: 1.4rem;
    font-style: normal;

    h3 {
        color: var(--main-color);
    }

    a {
        color: var(--primary-color);
        text-decoration: none;
    }
}