.menu {
    position: sticky;
    font-size: 3.5rem;
    align-self: center;
    cursor: pointer;
}

.slide {
    position: sticky;
    top: 3rem;
}

nav {
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
        padding: 0.25rem;
        margin: 0.1rem 0;
        color: var(--main-color);

        &:active,
        &:hover {
            color: white;
            background-color: var(--secundary-color);
        }
    }
}

// Mobile
@media all and (max-width: 1023px) {
    header {
        display: grid;
        grid-template-columns: 4rem 1fr 4rem;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
        padding: 0.3rem 0;
    }
    
    aside {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: #ffffff;
        transition: 0.1s;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: 5px solid var(--main-color);
    }

    .open {
        width: 100%;
        max-width: 40rem;
        padding-left: 1.5rem;

        nav {
            font-size: 1.6rem;
        }
    }

    .close {
        font-size: 4rem;
        cursor: pointer;
    }
}

@media all and (min-width: 425px) and (max-width: 1024px) {
 aside {
    border-right: 3px solid var(--main-color);
 }
}

// Desktop
@media all and (min-width: 1024px) {
    .navigation-title {
        display: block;
    }

    header, .menu, .close {
        display: none;
    }
}
