:root {
    --main-color: #0b68b4;
    --secundary-color: #d41675;
}

@import "./reset.scss";
@import "./navigation.scss";
@import "./profile.scss";
@import "./address.scss";
@import "./youtube.scss";

@import url("https://rsms.me/inter/inter.css");
html {
    font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif;
    }
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    color: #333;
    margin: 0 auto;
    font-size: 16px;
    padding: 0 1rem;
}

.logo {
    justify-self: center;
    max-height: 6rem;
    width: 6rem;
}

footer {
    margin: 6rem 0 1rem 0;
    text-align: center;
    color: #5c5c5c;
}

#video {
    margin: 4rem 0;
}

nav.referal {
    h3 {
        color: var(--main-color);
    }
    a:hover {
        color: var(--main-color);
        background-color: #fff;
        text-decoration: underline;
    }
}

section {
    margin: 1rem 0;
}

p {
    padding-bottom: 1rem;
}

h1 {
    padding-top: 2rem;
    color: var(--main-color);
}

h2 {
    padding-top: 2rem;
    color: var(--main-color);
}

h3 {
    padding-top: 1rem;
    color: var(--secundary-color);
}

li {
    margin-bottom: 5px;
}

figure {
    margin: 1rem 0;
}

figcaption {
    padding: 0.5rem 0;
    text-align: center;
    font-size: 1.5rem;
}

picture.img-desc > img {
    max-width: 80%;
    height: auto;
    margin: 1rem auto;
}

section {
    a {
        color: var(--secundary-color);
    }
}

.call {
    text-decoration: none;

    span {
        font-size: 1.6rem;
    }
}

@media all and (max-width: 1024px) {
    section,
    video {
        scroll-margin-top: 6rem;
    }

    main {
        max-width: 60rem;
        margin: 0 auto;
    }

    aside {
        margin-top: 0;
        padding-top: 0;
    }

    section:first-child {
        margin-top: 0;
    }
}

@media all and (min-width: 1024px) {
    body {
        margin: 5rem auto 5rem auto;
        max-width: 1100px;
        display: grid;
        padding: 0;
        grid-template-columns: 300px 1fr;
        grid-template-areas: "aside header" "aside main" "... footer";
    }

    aside {
        grid-area: aside;
        display: block;
        padding-left: .5rem;
    }

    main {
        grid-area: main;
        margin-left: 3rem;
        max-width: 60rem;
    }

    picture.img-desc > img {
        max-width: 60%;
        height: auto;
        margin: 1rem auto;
    }

    footer {
        grid-area: footer;
    }

    nav {
        margin-right: 2rem;
    }
}
